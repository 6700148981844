import { useEffect, useState } from "react";
import Image from "next/image";
import { JwMonitor } from "@4tn/webx-analytics";
import { styled } from "@mui/material/styles";
import AspectRatioContainer from "@common/AspectRatioContainer";
import InlinePlayer from "@pageContent/ArticleInlinePlayer/InlinePlayer";

interface ArticleHeaderProps {
  videoGuid?: string;
  imageUrl: string;
  title: string;
}

const HeaderImage = styled(Image)(({ theme }) => ({
  objectFit: "cover",
  [theme.breakpoints.up("md")]: {
    borderRadius: 8,
  },
}));

const ArticleHeaderWrapper = styled(AspectRatioContainer)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    width: `calc(100% + ${theme.spacing(4 * 2)})`,
    marginLeft: theme.spacing(-4),
  },
}));

const ArticleHeader: React.FC<ArticleHeaderProps> = ({ title, videoGuid, imageUrl }) => {
  const [isConsentAccepted, setIsConsentAccepted] = useState(false);

  useEffect(() => {
    if (videoGuid) JwMonitor.setPlayReason("autostart");

    const handleConsentChange = ({ isConsentModalClosed }: WebXEventParams["consentChange"]) => {
      if (!isConsentModalClosed) return;
      setIsConsentAccepted(true);
      window.eventBus.off("consentChange", handleConsentChange);
    };
    window.eventBus.on("consentChange", handleConsentChange, true);
    return () => {
      window.eventBus.off("consentChange", handleConsentChange);
    };
  }, [videoGuid]);

  if (!videoGuid)
    return (
      <ArticleHeaderWrapper aspectRatio="16:9">
        <HeaderImage src={imageUrl} alt={title} fill />
      </ArticleHeaderWrapper>
    );

  return (
    <ArticleHeaderWrapper aspectRatio="16:9">
      <InlinePlayer
        title=""
        duration={0}
        videoGuid={videoGuid}
        imageUrl={imageUrl}
        playerLoadedInitially={isConsentAccepted}
        isFullWidthXs
        enableFloating
      />
    </ArticleHeaderWrapper>
  );
};

export default ArticleHeader;
