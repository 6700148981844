import { styled } from "@mui/material/styles";
import { maxPageContentWidth, maxPageContentWidthMd } from "@constants/consts";

const ArticleContainer = styled("div")(({ theme }) => ({
  margin: "0 auto",
  width: "100%",
  display: "grid",
  gridTemplateColumns: "repeat(12, 1fr)",
  gap: theme.spacing(4),

  [theme.breakpoints.up("md")]: {
    marginTop: theme.spacing(8),
    paddingBottom: theme.spacing(4),
    maxWidth: maxPageContentWidthMd,
  },
  [theme.breakpoints.up("lg")]: {
    maxWidth: maxPageContentWidth,
    padding: theme.spacing(0, 24),
  },
  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(0, 4),
  },
}));

export default ArticleContainer;
