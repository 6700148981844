import Link from "next/link";
import { useQuery } from "@tanstack/react-query";
import TextSkeleton from "@pageContent/common/Skeletons/TextSkeleton";
import { useNoticeError } from "@utils/common/newRelic";
import trackContentClick from "@utils/common/trackContentClick";
import { fetchByEndpoint } from "@utils/pageContent/fetchData";
import * as Styled from "./ArticlePanel.styled";

const fetchCollection = async (url: string) => {
  const initialData = await fetchByEndpoint<LinkList | CategoryBasedCollection>(url);

  if (!initialData) return null;

  if (initialData.contentType === "categoryBasedCollection") {
    const justInCollection = await fetchByEndpoint<ReturnFetchData<PageLink>>(`${initialData.endpoint}?limit=5`);
    return { ...justInCollection, title: initialData.title, type: initialData.type };
  }

  return { ...initialData, items: initialData.items.slice(0, 5) };
};

export const PanelSkeletonNumeric: React.FC = () => {
  return (
    <>
      <TextSkeleton width="70%" height={24} sx={{ marginBottom: 5 }} />
      {[...Array(5)].map((_, i) => (
        <Styled.SkeletonsContainer key={`panel-skeleton-numeric-${i}`} hasNumbers>
          <TextSkeleton height={20} width={15} />
          <Styled.ArticleTitleSkeletonContainer>
            <TextSkeleton height={12} sx={{ marginBottom: 2 }} />
            <TextSkeleton height={12} sx={{ marginBottom: 2 }} />
            <TextSkeleton height={12} sx={{ marginBottom: 2 }} width={"85%"} />
          </Styled.ArticleTitleSkeletonContainer>
        </Styled.SkeletonsContainer>
      ))}
    </>
  );
};

export const PanelSkeleton: React.FC = () => {
  return (
    <>
      <TextSkeleton width="70%" height={24} sx={{ marginBottom: 5 }} />
      {[...Array(5)].map((_, i) => (
        <Styled.SkeletonsContainer key={`panel-skeleton-${i}`}>
          <TextSkeleton height={12} sx={{ marginBottom: 2 }} />
          <TextSkeleton height={12} sx={{ marginBottom: 2 }} />
          <TextSkeleton height={12} sx={{ marginBottom: 2 }} width={"85%"} />
        </Styled.SkeletonsContainer>
      ))}
    </>
  );
};

const ArticlePanel: React.FC<{ url: string; isNumeric?: boolean }> = ({ url, isNumeric }) => {
  const {
    data: collection,
    isLoading,
    error,
  } = useQuery({
    queryKey: [url],
    queryFn: () => fetchCollection(url),
  });

  useNoticeError(error, { queryKey: url });

  if (isLoading) return isNumeric ? <PanelSkeletonNumeric /> : <PanelSkeleton />;

  if (!collection) return null;

  return (
    <>
      <Styled.ArticlePanelTitle>{collection?.title}</Styled.ArticlePanelTitle>
      {collection?.items.map((collectionItem, index) => (
        <Styled.ArticlePanelItem
          key={collectionItem.id}
          hasBorder={index < collection.items.length - 1}
          onClick={() =>
            trackContentClick({
              position: index + 1,
              label: collection.title,
              ...collectionItem,
            })
          }
        >
          {collection.type === "numeric-collection" && (
            <Styled.ArticlePanelNumber>{index + 1}</Styled.ArticlePanelNumber>
          )}
          {collectionItem.url && (
            <Link href={collectionItem.url} target={collectionItem.target}>
              {collectionItem?.sponsor && (
                <Styled.LinkListSponsoredItemText>{collectionItem.sponsor.text}</Styled.LinkListSponsoredItemText>
              )}
              <Styled.ArticleTitle>{collectionItem.title}</Styled.ArticleTitle>
            </Link>
          )}
        </Styled.ArticlePanelItem>
      ))}
    </>
  );
};

export default ArticlePanel;
