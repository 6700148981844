import { styled } from "@mui/material/styles";
import AdSlot from "@pageContent/AdSlot";
import { AD_IDS } from "@constants/consts";
import { useHostConfig } from "@utils/common/getHostConfig";
import ArticlePanel from "./ArticlePanel";

export const ArticleCollectionPanelsContainer = styled("div")(() => ({
  gridColumn: "3 span",
  display: "block",
}));

const ArticleCollectionPanels: React.FC<{ shouldRenderAdSlot?: boolean }> = ({ shouldRenderAdSlot }) => {
  const {
    articleCollectionPanels: { topCollectionUrl, bottomCollectionUrl },
  } = useHostConfig();

  return (
    <ArticleCollectionPanelsContainer>
      <ArticlePanel url={topCollectionUrl} isNumeric />
      {shouldRenderAdSlot && <AdSlot id={AD_IDS.articlePanelBanner} />}
      {bottomCollectionUrl && <ArticlePanel url={bottomCollectionUrl} />}
    </ArticleCollectionPanelsContainer>
  );
};

export default ArticleCollectionPanels;
