export enum SocialShareItemEnum {
  MAIL = "mail",
  WHATSAPP = "whatsapp",
  FACEBOOK = "facebook",
  TWITTER = "x",
  LINK = "link",
}

export const socialShareVideoText = "Ik wil deze video met je delen:";

export const socialShareArticleText = "Lees dit artikel:";
