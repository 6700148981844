import Image from "next/image";
import { styled } from "@mui/material";
import { colors, fonts } from "@constants/cssVariables";

export const SponsoredLabelWrapper = styled("div")(({ theme }) => ({
  height: 56,
  display: "flex",
  position: "relative",
  justifyContent: "space-between",
  alignItems: "center",
  gap: theme.spacing(3),
  padding: theme.spacing(2, 0),
  borderTop: `1px solid ${colors.neutral10}`,
  borderBottom: `1px solid ${colors.neutral10}`,
  margin: theme.spacing(4, 0),

  [theme.breakpoints.up("md")]: {
    margin: theme.spacing(6, 0),
  },
}));

export const SponsoredLabelText = styled("div")(() => ({
  font: fonts.bodySmall,
  color: colors.neutral80,
  width: "100%",
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 2,
  overflow: "hidden",
  textOverflow: "ellipsis",
  overflowWrap: "break-word",
  maxWidth: "calc(100% - 112px)",
}));

export const SponsorLogo = styled(Image)(() => ({
  height: "auto",
  maxWidth: "100%",
  objectFit: "contain",
  objectPosition: "right",
}));

export const LogoContainer = styled("div")(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100px",
  height: "40px",
  position: "relative",
}));
