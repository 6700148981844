import * as Styled from "./SponsoredLabel.styled";

export const LOGO_ALT_TEXT = "sponsor-logo";

const SponsoredLabel: React.FC<{ sponsor: Sponsor }> = ({ sponsor }) => {
  return (
    <Styled.SponsoredLabelWrapper>
      <Styled.SponsoredLabelText>{sponsor.text}</Styled.SponsoredLabelText>
      {sponsor.imageUrl && (
        <Styled.LogoContainer>
          <Styled.SponsorLogo alt={LOGO_ALT_TEXT} src={sponsor.imageUrl} fill sizes="100px" />
        </Styled.LogoContainer>
      )}
    </Styled.SponsoredLabelWrapper>
  );
};

export default SponsoredLabel;
