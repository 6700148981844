import React from "react";
import dynamic from "next/dynamic";
import { styled } from "@mui/material/styles";
import AdSlot from "@pageContent/AdSlot";
import { ArticleCollectionPanelsContainer } from "@pageContent/ArticleCollectionPanels/ArticleCollectionPanels";
import { PanelSkeleton, PanelSkeletonNumeric } from "@pageContent/ArticleCollectionPanels/ArticlePanel";
import ArticleHeader from "@pageContent/ArticleHeader";
import Headline from "@pageContent/Headline";
import LinkList from "@pageContent/LinkList";
import RichText from "@pageContent/RichText";
import SocialSharing from "@pageContent/SocialSharing";
import SponsoredLabel from "@pageContent/SponsoredLabel";
import ArticleContainer from "@pageContent/common/ArticleContainer";
import ImageSkeleton from "@pageContent/common/Skeletons/ImageSkeleton";
import TextSkeleton from "@pageContent/common/Skeletons/TextSkeleton";
import { AD_IDS, ScreenSizes } from "@constants/consts";
import { RootNode } from "@typings/richText";
import useScreenSize from "@utils/common/useScreenSize";
import * as StyledAdSlot from "../AdSlot/AdSlot.styled";

const ArticleCollectionPanels = dynamic(() => import("@pageContent/ArticleCollectionPanels"), {
  loading: () => (
    <ArticleCollectionPanelsContainer>
      <PanelSkeletonNumeric />
      <PanelSkeleton />
    </ArticleCollectionPanelsContainer>
  ),
});

interface ArticleContentProps {
  article?: Article;
  isLoading: boolean;
}

export const ArticleGrid = styled("div")(({ theme }) => ({
  gridColumn: "12 span",

  [theme.breakpoints.up("lg")]: {
    gridColumnStart: 2,
    gridColumnEnd: 9,
  },

  "> span:first-of-type": {
    [theme.breakpoints.down("md")]: {
      width: "calc(100% + 32px)",
      marginLeft: theme.spacing(-4),
    },
    height: "auto",
    width: "auto",
  },
}));

const Container = styled("div")(({ theme }) => ({
  [`${StyledAdSlot.Container}`]: {
    gridColumn: "12 span",
    [theme.breakpoints.up("lg")]: {
      gridColumnStart: 2,
      gridColumnEnd: 12,
    },
  },
}));

const ArticleContent: React.FC<ArticleContentProps> = ({ article, isLoading }) => {
  const screenSize = useScreenSize();

  if (!article || isLoading) {
    return (
      <ArticleContainer>
        <ArticleGrid>
          <ImageSkeleton aspectRatio="16:9" />
          <TextSkeleton height={[28, 28, 36]} sx={{ marginTop: [8, 8, 10] }} />
          <TextSkeleton height={[28, 28, 36]} sx={{ marginTop: [3, 3, 4] }} width={["100%", "100%", "85%"]} />
          <TextSkeleton height={28} sx={{ marginTop: 3, display: ["block", "block", "none"] }} width={"75%"} />
          <TextSkeleton height={[12, 12, 16]} width={[115, 115, 134]} sx={{ marginTop: [6, 6, 8] }} />
          <TextSkeleton height={[12, 12, 16]} width={[177, 177, 180]} sx={{ marginTop: 3 }} />
          <TextSkeleton height={16} sx={{ marginTop: [5, 5, 8] }} />
          <TextSkeleton height={16} sx={{ marginTop: 3, display: ["block", "block", "none"] }} />
          <TextSkeleton height={16} width={"85%"} sx={{ marginTop: 3 }} />
        </ArticleGrid>
        {(screenSize === ScreenSizes.lg || screenSize === ScreenSizes.xl) && (
          <ArticleCollectionPanelsContainer>
            <PanelSkeletonNumeric />
            <PanelSkeleton />
          </ArticleCollectionPanelsContainer>
        )}
      </ArticleContainer>
    );
  }
  const [articleSchema] = Array.isArray(article.structuredData) ? article.structuredData : [article.structuredData];

  return (
    <Container>
      {screenSize !== ScreenSizes.sm && !article.sponsor && screenSize && (
        <ArticleContainer>
          <AdSlot id={AD_IDS.topBanner} />
        </ArticleContainer>
      )}
      <ArticleContainer>
        <ArticleGrid>
          <ArticleHeader {...article} />
          <Headline {...article} />
          {article.sponsor && <SponsoredLabel sponsor={article.sponsor} />}
          <RichText richText={article.content as RootNode} isSponsored={!!article.sponsor} />
          <SocialSharing socialTitle={article.socialTitle} url={articleSchema?.mainEntityOfPage} />
          {screenSize && !article.sponsor && <AdSlot id={AD_IDS.bottomBanner} />}
          {!!article.related.items.length && <LinkList items={article.related.items} title={article.related.title} />}
        </ArticleGrid>
        {(screenSize === ScreenSizes.lg || screenSize === ScreenSizes.xl) && (
          <ArticleCollectionPanels shouldRenderAdSlot={screenSize === ScreenSizes.xl && !article.sponsor} />
        )}
      </ArticleContainer>
    </Container>
  );
};

export default ArticleContent;
