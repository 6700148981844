import { Fragment } from "react";
import Tealium from "@4tn/webx-analytics";
import { styled } from "@mui/material/styles";
import IconButton from "@common/Buttons/IconButton";
import { BUTTON_SIZE, VARIANT } from "@constants/button";
import { IconEnum } from "@constants/consts";
import { colors, fonts } from "@constants/cssVariables";
import { SocialShareItemEnum, socialShareArticleText, socialShareVideoText } from "@constants/socialSharing";
import { TEALIUM_EVENT_CATEGORY, TEALIUM_EVENT_LABEL, TEALIUM_EVENT_NAME } from "@constants/tealium.constants";
import Popper from "./Popper";

type SocialShareItemType = {
  name: SocialShareItemEnum;
  accessibilityLabel: string;
  icon: IconEnum;
  hasPopper?: boolean;
  clickHandler: (url?: string, isVideo?: boolean) => void;
};

const trackClick = (eventLabel: string, isVideo?: boolean) => {
  Tealium.link({
    event_category: isVideo ? TEALIUM_EVENT_CATEGORY.VIDEO : TEALIUM_EVENT_CATEGORY.ARTICLE,
    event_name: TEALIUM_EVENT_NAME.SHARE_INTERACTION,
    event_label: eventLabel,
  });
};

const getFallbackUrl = () => {
  const [url] = window.location.href.split("?");
  return url;
};

const socialShareItems: SocialShareItemType[] = [
  {
    name: SocialShareItemEnum.WHATSAPP,
    accessibilityLabel: "Deel via Whatsapp",
    icon: IconEnum.WHATSAPP,
    clickHandler: (url, isVideo) => {
      window.open(
        `https://api.whatsapp.com/send?text=${isVideo ? socialShareVideoText : socialShareArticleText} ${
          url || getFallbackUrl()
        }`,
        "_blank"
      );
      trackClick(TEALIUM_EVENT_LABEL.SOCIAL_SHARE_LABELS.WHATSAPP, isVideo);
    },
  },
  {
    name: SocialShareItemEnum.MAIL,
    accessibilityLabel: "Verstuur via e-mail",
    icon: IconEnum.MAIL,
    clickHandler: (url, isVideo) => {
      window.open(
        `mailto:?body=${isVideo ? socialShareVideoText : socialShareArticleText} ${url || getFallbackUrl()}`,
        "_blank"
      );
      trackClick(TEALIUM_EVENT_LABEL.SOCIAL_SHARE_LABELS.EMAIL);
    },
  },
  {
    name: SocialShareItemEnum.FACEBOOK,
    accessibilityLabel: "Deel op Facebook",
    icon: IconEnum.FACEBOOK,
    clickHandler: (url, isVideo) => {
      window.open(`https://www.facebook.com/sharer.php?u=${url || getFallbackUrl()}`, "_blank");
      trackClick(TEALIUM_EVENT_LABEL.SOCIAL_SHARE_LABELS.FACEBOOK, isVideo);
    },
  },
  {
    name: SocialShareItemEnum.TWITTER,
    accessibilityLabel: "Deel op X",
    icon: IconEnum.TWITTER,
    clickHandler: (url, isVideo) => {
      window.open(
        `https://twitter.com/intent/tweet?text=${isVideo ? socialShareVideoText : socialShareArticleText} ${
          url || getFallbackUrl()
        }`,
        "_blank"
      );
      trackClick(TEALIUM_EVENT_LABEL.SOCIAL_SHARE_LABELS.X, isVideo);
    },
  },
  {
    name: SocialShareItemEnum.LINK,
    accessibilityLabel: "Kopieer link",
    icon: IconEnum.LINK,
    hasPopper: true,
    clickHandler: (url, isVideo) => {
      window.navigator.clipboard.writeText(url || getFallbackUrl());
      trackClick(TEALIUM_EVENT_LABEL.SOCIAL_SHARE_LABELS.COPY_LINK, isVideo);
    },
  },
];

export const SocialSharingContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(3),
  margin: theme.spacing(4, 0),

  [theme.breakpoints.up("md")]: {
    gap: theme.spacing(4),
    margin: theme.spacing(6, 0),
  },
}));

const SocialSharingTitle = styled("p")(() => ({
  font: fonts.header3,
  color: colors.neutral90,
}));

const SocialSharingItemsContainer = styled("div")(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(2),
}));

interface SocialSharingProps {
  socialTitle?: string;
  url?: string;
  isVideo?: boolean;
}

const SocialSharingItem: React.FC<SocialShareItemType & { url?: string; isVideo?: boolean }> = ({
  hasPopper,
  accessibilityLabel,
  url,
  isVideo,
  icon,
  clickHandler,
}) => {
  const Wrapper = hasPopper ? Popper : Fragment;
  return (
    <Wrapper>
      <IconButton
        icon={icon}
        size={BUTTON_SIZE.MEDIUM}
        variant={VARIANT.PRIMARY}
        color={colors.neutral0}
        aria-label={accessibilityLabel}
        onClick={() => clickHandler(url, isVideo)}
      />
    </Wrapper>
  );
};

const SocialSharing: React.FC<SocialSharingProps> = ({ socialTitle, url, isVideo }) => (
  <SocialSharingContainer>
    {socialTitle && <SocialSharingTitle>{socialTitle}</SocialSharingTitle>}
    <SocialSharingItemsContainer>
      {socialShareItems.map((item) => (
        <SocialSharingItem key={item.name} {...item} url={url} isVideo={isVideo} />
      ))}
    </SocialSharingItemsContainer>
  </SocialSharingContainer>
);

export default SocialSharing;
