import { styled } from "@mui/material/styles";
import { colors, fonts } from "@constants/cssVariables";

export const ArticlePanelItem = styled("div", { shouldForwardProp: (prop) => prop !== "hasBorder" })<{
  hasBorder: boolean;
}>(({ theme, hasBorder }) => ({
  display: "flex",
  font: fonts.header7,
  color: colors.neutral80,
  marginBottom: theme.spacing(4),
  borderBottom: hasBorder ? `1px solid ${colors.neutral10}` : "none",
}));

export const ArticlePanelNumber = styled("div")(({ theme }) => ({
  font: fonts.header4,
  color: colors.neutral40,
  minWidth: 24,
  marginRight: theme.spacing(3),
  textAlign: "center",
}));

export const ArticlePanelTitle = styled("div")(({ theme }) => ({
  font: fonts.header4,
  color: colors.neutral90,
  marginBottom: theme.spacing(3),
}));

export const ArticleTitle = styled("div")(({ theme }) => ({
  overflow: "hidden",
  display: "-webkit-box",
  textOverflow: "ellipsis",
  wordBreak: "break-word",
  marginBottom: theme.spacing(4),
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: "3",

  "&:hover": {
    textDecoration: "underline",
  },
}));

export const SkeletonsContainer = styled("div", { shouldForwardProp: (prop) => prop !== "hasNumbers" })<{
  hasNumbers?: boolean;
}>(({ theme, hasNumbers }) => ({
  paddingBottom: theme.spacing(4),
  marginBottom: theme.spacing(4),
  borderBottom: `1px solid ${colors.neutral10}`,
  display: hasNumbers ? "flex" : "block",
  gap: theme.spacing(4),

  "&:nth-of-type(5n)": {
    borderBottom: "none",
  },
}));

export const ArticleTitleSkeletonContainer = styled("div")(({}) => ({
  width: "100%",
}));

export const LinkListSponsoredItemText = styled("div")(({ theme }) => ({
  font: fonts.bodyExtraSmall,
  color: colors.neutral40,
  marginBottom: theme.spacing(1),
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 1,
  overflow: "hidden",
  textOverflow: "ellipsis",
}));
